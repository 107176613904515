import styles from "../styles/components/nabBar.module.css";
import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Button from "./button";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { IoMenu } from "react-icons/io5";

const NavBar = () => {
  const ref = useRef();
  const [nav, setNav] = useState(true);
  const [services, setServices] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 769) setNav(false);
    window.addEventListener("resize", (e) => {
      if (window.innerWidth < 769) {
        setNav(false);
      } else {
        setNav(true);
      }
    });
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (services && ref.current && !ref.current.contains(e.target)) {
        setServices(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [services]);

  const toggleNav = () => {
    setNav(!nav);
  };

  const toggleServices = (e) => {
    setServices(!services);
    e.preventDefault();
  };

  const hideNav = () => {
    if (window.innerWidth < 769) setNav(false);
  };

  const hideServices = () => {
    setServices(false);
  };

  return (
    <nav className={styles.header}>
      <div className={styles.headerLeft}>
        <NavLink to="/">
          <img src="/images/logo.svg" width={120} height={55} alt="logo" />
        </NavLink>
      </div>

      <div
        className={styles.headerMiddle}
        style={{ display: nav ? "flex" : "none" }}
      >
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? styles.headerLinkActive : styles.headerLink
          }
          onClick={hideNav}
        >
          About
        </NavLink>
        <NavLink
          to="/services"
          className={({ isActive }) =>
            isActive ? styles.headerLinkActive : styles.headerLink
          }
          onClick={toggleServices}
          ref={ref}
        >
          Services
          <span>
            {services ? (
              <IoChevronUpOutline
                size={19}
                style={{ marginBottom: -3, marginLeft: 3, marginRight: -3 }}
              />
            ) : (
              <IoChevronDownOutline
                size={19}
                style={{ marginBottom: -3, marginLeft: 3, marginRight: -3 }}
              />
            )}
          </span>
        </NavLink>
        <div
          className={styles.servicesMenu}
          style={{ display: services ? "flex" : "none" }}
          ref={ref}
        >
          <NavLink
            to="/service/development"
            className={styles.headerLinkMenu}
            onClick={hideServices}
          >
            Development
          </NavLink>
          <NavLink
            to="/service/branding"
            className={styles.headerLinkMenu}
            onClick={hideServices}
          >
            Branding
          </NavLink>
        </div>
        <NavLink
          to="/work"
          className={({ isActive }) =>
            isActive ? styles.headerLinkActive : styles.headerLink
          }
          onClick={hideNav}
        >
          Work
        </NavLink>
        <NavLink
          to="/team"
          className={({ isActive }) =>
            isActive ? styles.headerLinkActive : styles.headerLink
          }
          onClick={hideNav}
        >
          Team
        </NavLink>
        {/* <NavLink
          to="/signin"
          className={`${styles.headerLink} ${styles.signInNav}`}
          onClick={hideNav}
        >
          Sign In
        </NavLink> */}
      </div>
      <div className={styles.mobile_bars} onClick={toggleNav}>
        <IoMenu className={styles.navIcon} size={30} />
      </div>

      <div className={styles.headerRight}>
        <Button
          text="Lets Chat"
          link="letschat"
          extraStyles={{ marginTop: 5 }}
        />
        {/* <NavLink to="/signin" className={styles.signin} onClick={hideNav}>
          Sign In
        </NavLink> */}
      </div>
    </nav>
  );
};

export default NavBar;
