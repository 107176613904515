export const blogData = [
  {
    id: "hiddenpalace",
    title: "Hidden Palace",
    date: "June 17, 2023",
    excerpt:
      "A Sweet Collaboration: Redesigning Hidden Palace's Macaroon Website with Figma and EditorX",
    markdown: "../../posts/hiddenpalace.md",
  },
  {
    id: "billi",
    title: "Billi :)",
    date: "June 17, 2023",
    excerpt:
      "Streamlining Subscriptions: A Freelancer's Guide to Designing an Intuitive Subscription Manager App for Billi",
    markdown: "../../posts/hiddenpalace.md",
  },
  {
    id: "grainmarket",
    title: "Grain Market",
    date: "June 17, 2023",
    excerpt:
      "Transforming the Grain Industry: A Freelance Journey with Grain Market's Website Revamp",
    markdown: "../../posts/hiddenpalace.md",
  },
  {
    id: "appstore-screenshots",
    title: "Appstore Screenshots",
    date: "July 3, 2023",
    excerpt:
      "A Case Study on App Store Screenshots: Analyzing Complex, Simple, and Super Simple Designs",
    markdown: "../../posts/appstore-screenshots.md",
  },
];

export default {
  blogData,
};
