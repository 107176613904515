import { useLayoutEffect, useMemo, useState } from "react";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { useTable } from "react-table";
import SectionHeader from "../../../components/sectionHeader";
import InfoBanner from "../../../components/infoBanner";
import ContactBanner from "../../../components/contactBanner";
import styles from "../../../styles/pages/services.module.css";
import OfferItem from "../../../components/items/offerItem";
import EstimateModal from "../../../components/estimateModal";
import { motion } from "framer-motion";

const DevelopmentPage = () => {
  const [modal, setModal] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Service",
        accessor: "service",
      },
      {
        Header: "Price",
        accessor: "price",
      },
    ],
    []
  );

  const data = useMemo(
    () => [
      {
        service: "3 Month Support (Includes unlimited revisions)",
        price: "$200",
      },
      { service: "Single (1) revision", price: "$30" },
      { service: "Domain registration", price: "$50" },
      { service: "Can't see what you want? Contact us!", price: "Free" },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const toggleAdditionalServices = () => {
    setAddOpen(!addOpen);
  };

  return (
    <motion.div
      key="dev"
      initial="initial"
      animate="animate"
      transition={{ type: "linear", duration: 0.5 }} // Set the transition to linear
      variants={{
        initial: {
          opacity: 0,
          y: 10,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      }}
    >
      <div>
        <SectionHeader
          title="Development (Web/App)"
          description="I offer a range of services for your business, whether if you are starting from scratch or need some help growing"
          img="/images/dev.svg"
          reversed={true}
        />

        <InfoBanner />

        <div className={styles.InfoWrap}>
          <h1>How it works</h1>
          <p>
            I offer affordable services for everyone, our offerings are
            constantly growing. Simply pick the service that interests you then
            head over to 'Lets Talk' and start a conversation with us!
          </p>
        </div>

        <div className={styles.InfoWrap}>
          <h1>What we offer</h1>
          <p>Keep an eye out, our offerings are constantly growing!</p>
        </div>

        <div className={styles.offersWrap}>
          <OfferItem
            name="Basic Web Development"
            description="Using either Webflow, Wix, Squarespace, or EditorX. You pick!"
            img="/icons/services/basic.svg"
            quoteButton={true}
            additions={["Responsive design"]}
            onOpen={() => setModal(true)}
          />
          <OfferItem
            name="Custom Web Development"
            description="Built with React, Vue, Next.Js, and more!"
            img="/icons/services/custom.svg"
            additions={["Responsive design", "Source code"]}
            quoteButton={true}
            onOpen={() => setModal(true)}
          />
          <OfferItem
            name="Static Website (Template)"
            description="Use our many templates to quickly get a website up. Plus we can host it!"
            img="/icons/services/static.svg"
            offer="Flat rate: $500CAD"
            hosting="Hosting (Op): $5/Mo"
          />
          <OfferItem
            name="Website/App Design"
            description="I help you get a design for your product. This includes responsiveness and more!"
            img="/icons/services/design.svg"
            offer="Flat rate: $50CAD/screen"
          />
          <OfferItem
            name="App Development"
            description="Built with Expo or React Native"
            img="/icons/services/mobile.svg"
            additions={["Source code", "Two Platforms"]}
            quoteButton={true}
            onOpen={() => setModal(true)}
          />
        </div>

        <div className={styles.additionServicesWrap}>
          <div
            className={styles.additionServicesTitle}
            onClick={toggleAdditionalServices}
          >
            <p>Additional Services</p>
            {addOpen ? (
              <IoChevronUpOutline size={20} style={{ marginLeft: 3 }} />
            ) : (
              <IoChevronDownOutline size={20} style={{ marginLeft: 3 }} />
            )}
          </div>
          {addOpen && (
            <div>
              <table {...getTableProps()} className={styles.serviceTable}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={index % 2 === 0 ? styles.even : styles.odd}
                      >
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <EstimateModal isOpen={modal} onClose={() => setModal(false)} />

        <ContactBanner />
      </div>
    </motion.div>
  );
};

export default DevelopmentPage;
