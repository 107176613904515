import SectionHeader from "../../../components/sectionHeader";
import InfoBanner from "../../../components/infoBanner";
import ContactBanner from "../../../components/contactBanner";
import styles from "../../../styles/pages/services.module.css";
import OfferItem from "../../../components/items/offerItem";
import { useLayoutEffect } from "react";
import Animation from "../../animation";

const BrandingPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Animation>
      <div>
        <SectionHeader
          title="Brand Identity"
          description="I offer a range of services for your business, whether if you are starting from scratch or need some help growing"
          img="/images/branding.svg"
          reversed={true}
        />

        <InfoBanner />

        <div className={styles.InfoWrap}>
          <h1>How it works</h1>
          <p>
            I offer affordable services for everyone, our offerings are
            constantly growing. Simply pick the service that interest you then
            head over to ‘Lets Talk’ and start a convo with us!
          </p>
        </div>

        <div className={styles.InfoWrap}>
          <h1>What I offer</h1>
          <p>Keep an eye out, our offerings are constantly growing!</p>
        </div>
        <div className={styles.offersWrap}>
          <OfferItem
            name="Logo and Identity Design"
            description="Help you design your brand's identity"
            img="/icons/brandid.svg"
          />
          <OfferItem
            name="Brand Strategy + Guidelines"
            description="Building your brands design guidelines and strategy"
            img="/icons/brandid.svg"
          />
          <OfferItem
            name="AppStore Screenshots"
            description="Creating the AppStore's appearance"
            img="/icons/brandid.svg"
            offer="Flat rate: $79CAD"
          />
        </div>

        <ContactBanner />
      </div>
    </Animation>
  );
};

export default BrandingPage;
