import React, { useEffect, useState } from "react";
import styles from "../styles/components/estimateModal.module.css";
import { IoClose } from "react-icons/io5";
import InputField from "./inputField";
import Dropdown from "./dropdownField";
import Button from "./button";
import Checkbox from "./checkboxField";

const EstimateModal = ({ isOpen, onClose }) => {
  const [service, setService] = useState("");
  const [pages, setPages] = useState("");
  const [error, setError] = useState("");
  const [generated, setGenerated] = useState("");
  //WEB
  const [redesign, setRedesign] = useState(false);
  const [hosting, setHosting] = useState(false);
  const [domain, setDomain] = useState(false);
  const [seo, setSEO] = useState(false);
  const [cms, setCMS] = useState(false);
  const [ecommerce, setEcommerce] = useState(false);
  const [analytics, setAnalytics] = useState(false);
  //APP
  const [submission, setSubmission] = useState(false);
  const [iconsplash, setIconSplash] = useState(false);

  const handleServiceChange = (e) => {
    setService(e.id);
    setGenerated("");
    setError("");
  };

  const handlePageChange = (e) => {
    setPages(e.id);
    setGenerated("");
    setError("");
  };

  const handleGenerate = (e) => {
    if (!pages) setError("Please select a Page");
    if (!service && !pages) setError("Please select a Service");

    let pageCost = getPageExtra(pages);

    switch (service) {
      case 1:
        basicGen(pageCost, pages);
        break;
      case 2:
        customGen(pageCost + 300, pages);
        break;
      case 3:
        appGen(pageCost + 200, pages);
        break;
      default:
        break;
    }
  };

  const basicGen = (pageCost, pages) => {
    let cost = 600 + pageCost;
    if (redesign) cost += 150 * pages;
    if (domain) cost += 50;
    if (hosting) cost += 30;
    if (seo) cost += 100;
    if (cms) cost += 150;
    if (ecommerce) cost += 200;
    if (analytics) cost += 100;

    setGenerated(cost);
  };

  const customGen = (pageCost, pages) => {
    let cost = 1000 + pageCost;
    if (redesign) cost += 150 * pages;
    if (domain) cost += 50;
    if (hosting) cost += 30;
    if (seo) cost += 100;
    if (cms) cost += 150;
    if (ecommerce) cost += 200;
    if (analytics) cost += 100;

    setGenerated(cost);
  };

  const appGen = (pageCost, pages) => {
    let cost = 1500 + pageCost;
    if (redesign) cost += 150 * pages;
    if (submission) cost += 150;
    if (iconsplash) cost += 50;
    if (cms) cost += 150;

    setGenerated(cost);
  };

  const getPageExtra = (pages) => {
    switch (pages) {
      case 1:
        return 0;
        break;
      case 2:
        return 400;
        break;
      case 3:
        return 800;
        break;
      default:
        break;
    }
  };

  return (
    <div className={`${styles.modal} ${isOpen ? styles.open : ""}`}>
      <div className={styles.modalWrap}>
        <div className={styles.modalTitleWrap}>
          <h2>Estimate</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <IoClose size={30} />
          </button>
        </div>
        <div className={styles.modalContentWrap}>
          <Dropdown
            title="Select a service"
            options={[
              { label: "Basic Web Development", id: 1 },
              { label: "Custom Web Development", id: 2 },
              { label: "App Development", id: 3 },
            ]}
            onSelect={handleServiceChange}
          />
          <Dropdown
            title="Amount of pages"
            options={[
              { label: "1-2", id: 1 },
              { label: "3-5", id: 2 },
              { label: "6-10", id: 3 },
            ]}
            onSelect={handlePageChange}
          />
        </div>
        {pages && service === 1 && (
          <div className={styles.modalSubContentWrap}>
            <p>Optional Add-ons</p>
            <Checkbox
              label="Design"
              checked={redesign}
              onChange={setRedesign}
            />
            <Checkbox label="Hosting" checked={hosting} onChange={setHosting} />
            <Checkbox label="Domain" checked={domain} onChange={setDomain} />
            <Checkbox
              label="SEO optimization"
              checked={seo}
              onChange={setSEO}
            />
            <Checkbox
              label="Content Management System"
              checked={cms}
              onChange={setCMS}
            />
            <Checkbox
              label="E-commerce"
              checked={ecommerce}
              onChange={setEcommerce}
            />
            <Checkbox
              label="Analytics/Tracking"
              checked={analytics}
              onChange={setAnalytics}
            />
          </div>
        )}
        {pages && service === 2 && (
          <div className={styles.modalSubContentWrap}>
            <p>Optional Add-ons</p>
            <Checkbox
              label="Design"
              checked={redesign}
              onChange={setRedesign}
            />
            <Checkbox label="Hosting" checked={hosting} onChange={setHosting} />
            <Checkbox label="Domain" checked={domain} onChange={setDomain} />
            <Checkbox
              label="SEO optimization"
              checked={seo}
              onChange={setSEO}
            />
            <Checkbox
              label="Content Management System"
              checked={cms}
              onChange={setCMS}
            />
            <Checkbox
              label="E-commerce"
              checked={ecommerce}
              onChange={setEcommerce}
            />
            <Checkbox
              label="Analytics/Tracking"
              checked={analytics}
              onChange={setAnalytics}
            />
          </div>
        )}
        {pages && service === 3 && (
          <div className={styles.modalSubContentWrap}>
            <p>Optional Add-ons</p>
            <Checkbox
              label="Design"
              checked={redesign}
              onChange={setRedesign}
            />
            <Checkbox
              label="App Submission"
              checked={submission}
              onChange={setSubmission}
            />
            <Checkbox
              label="App Icon/Splash Screen"
              checked={iconsplash}
              onChange={setIconSplash}
            />
            <Checkbox
              label="Content Management System"
              checked={cms}
              onChange={setCMS}
            />
          </div>
        )}
        <div className={styles.modalFooterWrap}>
          <p>{error}</p>
          <Button text="Generate" onClickPress={handleGenerate} />
        </div>
        {generated && (
          <div className={styles.modalResultWrap}>
            <p>
              We estimate it will cost at minimum <b>${generated}CAD*</b>
            </p>
            <p className={styles.estimateDisclaimer}>
              *Please note this is just an estimate, While we try our best to
              keep cost as close as to the estimate there are other costs which
              *might* arrise which depends from project to project.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EstimateModal;
