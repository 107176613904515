import styles from "../styles/components/button.module.css";
import { Link } from "react-router-dom";

const Button = ({ text, link, icon, newTab, onClickPress, extraStyles }) => {
  const buttonItem = () => {
    return (
      <div className={styles.button} style={{ ...extraStyles }}>
        {icon}
        <p>{text}</p>
      </div>
    );
  };

  if (link) {
    return (
      <Link to={link} target={newTab ? "_blank" : "_self"}>
        {buttonItem()}
      </Link>
    );
  } else {
    return <div onClick={onClickPress}>{buttonItem()}</div>;
  }
};

export default Button;
