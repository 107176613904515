/*index.jsx*/
import React from "react";
import ContactBanner from "../../components/contactBanner";
import InfoBanner from "../../components/infoBanner";
import ServiceItem from "../../components/items/serviceItem";
import ServiceMiniItem from "../../components/items/serviceMiniItem";
import styles from "../../styles/pages/home.module.css";
import SectionHeader from "../../components/sectionHeader";
import Animation from "../animation";

//Functional Component
const HomePage = () => {
  return (
    <Animation>
      <>
        <div>
          <SectionHeader
            title="Your partner in creating and growing your business"
            description="I transform your ideas into reality. By building digital products, brands and experiences"
            img="/images/home.svg"
          />

          <InfoBanner />

          <div className={styles.offersWrap}>
            <div className={styles.offersLeft}>
              <ServiceMiniItem
                name="Development (Web/App)"
                description="Unleash your online presence. From stunning websites to impactful web apps, make a lasting digital impression."
                img="/icons/dev.svg"
              />
              <ServiceMiniItem
                name="Brand Identity"
                description="Brand identity shapes your unique business personality, combining visuals, messaging, and positioning to resonate with your audience."
                img="/icons/brandid.svg"
              />
            </div>
            <div className={styles.offersRight}>
              <h1>How I can help</h1>
              <p>
                I specialize in web development and brand identity solutions. I
                craft captivating websites and web applications, combining
                stunning design with seamless functionality. Additionally, I
                create compelling brand identities that leave a lasting impact.
                Whether you're looking to establish your online presence or
                revamp your brand, I'm here to guide you.
                <br />
                <br />
                Let's collaborate and bring your vision to life. Contact us
                today.
              </p>
            </div>
          </div>

          <div className={styles.serviceTitleWrap}>
            <h1>Services</h1>
            <p>
              I offer affordable services for everyone, our offerings are
              constantly growing. Simply pick the service that interest you then
              head over to ‘Lets Talk’ and start a convo with us!
            </p>
          </div>
          <div className={styles.servicesWrap}>
            <ServiceItem
              name="Development (Web/App)"
              description={`- Basic Web Development
          - Custom Web Development
          - App Development
          - Website/App Design
          - Single page Website
          + many more`}
              url="/service/development"
            />
            <ServiceItem
              name="Brand Identity"
              description={`-  Logo and Identity Design 
          - Brand Strategy + Guidelines
          - AppStore Screenshots
          + many more`}
              url="../service/branding"
            />
          </div>

          <ContactBanner />
        </div>
      </>
    </Animation>
  );
};

export default HomePage;
