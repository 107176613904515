import MainHeader from "../../components/mainHeader";
import InfoBanner from "../../components/infoBanner";
import ContactBanner from "../../components/contactBanner";
import WorkItem from "../../components/items/workItem";
import styles from "../../styles/pages/work.module.css";
import { useLayoutEffect } from "react";
import { motion } from "framer-motion";
import Animation from "../animation";

const WorkPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Animation>
      <div>
        <MainHeader
          title="Our Portfolio"
          subtitle="I transformed these ideas into reality. By building digital products, brands and experiences"
        />
        <InfoBanner />
        <div className={styles.work}>
          <WorkItem
            img="/images/work/hiddenpalace2.png"
            type="Basic Web Development"
            title="Hidden Palace"
            desc="Discover my freelance project with Hidden Palace, an online macaroon retailer. Using Figma, I redesigned their website, capturing their brand essence. Then, with EditorX, I transformed the design into a functional, responsive website."
            link="/work/hiddenpalace"
          />
          <WorkItem
            img="/images/work/billi.png"
            type="Mobile Design"
            title="Billi :)"
            desc="Discover how Figma, user-centric design, and effective communication shape a visually appealing and intuitive interface. Witness the transformative power of collaboration and empathetic design thinking."
            link="/work/billi"
          />
          {/* <WorkItem
            img="/images/work/grainmarket.png"
            type="Custom Web Development"
            title="Grain Market"
            desc="Combining Figma for captivating design and Next.js for enhanced performance. Highlights their unique value proposition, seamless user experience, and commitment to sustainability. Redefining the online presence of companies in traditional industries."
            link="/work/grainmarket"
          /> */}
          <WorkItem
            img="/images/work/appscreenshot1.png"
            type="AppStore Screenshots"
            title="AppStore Screenshots"
            desc="As the app market gets competitive, developers seek innovative ways to capture users' attention. Key aspect: compelling App Store screenshots. We compare three types: complex, simple, and super simple. Analyzing strengths and weaknesses to optimize visual marketing."
            link="/work/appstore-screenshots"
          />
        </div>
        <ContactBanner />
      </div>
    </Animation>
  );
};

export default WorkPage;
