import { IoArrowForward } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import styles from "../../styles/components/workItem.module.css";
import Button from "../button";

const WorkItem = ({ title, desc, img, link, newTab }) => {
  return (
    <div className={styles.workWrap}>
      <img className={styles.workItemImage} src={img} alt={title} />
      <div className={styles.workItemContent}>
        <h1>{title}</h1>
        <p className={styles.workItemDesc}>{desc}</p>
        <div className={styles.workOptions}>
          <Button text="Learn More" link={link} newTab={newTab} />
          <NavLink to="letschat">
            <div className={styles.letschat}>
              <NavLink to="../../letschat">
                <p>Lets Chat</p>
              </NavLink>
              <IoArrowForward size={19} />
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default WorkItem;
