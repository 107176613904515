import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/main/home";
import Layout from "./pages/layout";
import "./styles/globals.css";
import WorkPage from "./pages/main/work";
import TeamPage from "./pages/main/team";
import NotFound from "./pages/404";
import DevelopmentPage from "./pages/main/services/development";
import BrandingPage from "./pages/main/services/branding";
import WorkBlog from "./pages/main/workBlog";
import SignInPage from "./pages/dashboard/signIn";
import LetsChatPage from "./pages/main/letsChat";
import PrivacyPage from "./pages/main/privacy";
import TermsPage from "./pages/main/terms";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="service">
            <Route path="development" element={<DevelopmentPage />} />
            <Route path="branding" element={<BrandingPage />} />
            <Route index element={<NotFound />} />
          </Route>
          <Route path="work">
            <Route path=":workid" element={<WorkBlog />} />
            <Route index element={<WorkPage />} />
          </Route>
          <Route path="team" element={<TeamPage />} />
          <Route path="LetsChat" element={<LetsChatPage />} />
          <Route path="signin" element={<SignInPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
