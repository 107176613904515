import styles from "../../styles/components/teamItem.module.css";

const TeamItem = ({
  img,
  name,
  role,
  description,
  linkedin,
  twitter,
  link,
}) => {
  return (
    <div className={styles.teamItem}>
      <img src={img} className={styles.teamImg} alt={name} />
      <h1>{name}</h1>
      <h3>{role}</h3>
      <p>{description}</p>
      <div className={styles.socials}>
        {linkedin && (
          <a href={linkedin} target="_blank" rel="noreferrer">
            <img
              src="/icons/linkedin.svg"
              className={styles.footerSocialIcon}
              alt="linkedin"
            />
          </a>
        )}
        {twitter && (
          <a href={twitter} target="_blank" rel="noreferrer">
            <img
              src="/icons/twitter.svg"
              className={styles.footerSocialIcon}
              alt="twitter"
            />
          </a>
        )}
        {link && (
          <a href={link} target="_blank" rel="noreferrer">
            <img
              src="/icons/Link.svg"
              className={styles.footerSocialIcon}
              alt="link"
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default TeamItem;
