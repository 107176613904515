import styles from "../../styles/pages/workBlog.module.css";
import Button from "../../components/button";
import { useEffect, useLayoutEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams, useNavigate } from "react-router-dom";
import { blogData } from "../../utils/blogs";
import { ColorRing } from "react-loader-spinner";
import Animation from "../animation";

const WorkBlog = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    let id = params.workid;
    let blog = blogData.find((obj) => obj.id === id);
    if (blog) {
      import(`../../../public/posts/${id}.txt`)
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => {
              setText(res);
              setTitle(blog.title);
              setDate(blog.date);
              setDescription(blog.excerpt);
            })
            .finally(() => setLoading(false));
        })
        .finally(() => setLoading(false));
    } else {
      navigate("/404", { replace: true });
    }
  }, []);

  const BlogImage = (props) => {
    return <img {...props} style={{ maxWidth: "10%" }} />;
  };

  return (
    <Animation>
      <div>
        <div className={styles.postWrap}>
          {loading || !title ? (
            <div className={styles.loadingWrap}>
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            </div>
          ) : (
            <div className={styles.post}>
              <div className={styles.postHeader}>
                <h3>Published on {date}</h3>
                <h1>{title}</h1>
                <p>{description}</p>
              </div>
              <div className={styles.postBody}>
                <ReactMarkdown
                  children={text}
                  renderers={{ Image: BlogImage }}
                />
              </div>
              <div className={styles.postOutro}>
                <Button text="Back to Home" link="/work" />
              </div>
            </div>
          )}
        </div>
      </div>
    </Animation>
  );
};

export default WorkBlog;
