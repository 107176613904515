import MainHeader from "../../components/mainHeader";
import styles from "../../styles/pages/team.module.css";
import TeamItem from "../../components/items/teamItem";
import Animation from "../animation";

const TeamPage = () => {
  return (
    <Animation>
      <div>
        <MainHeader
          title="Meet the team"
          subtitle="This is the brains behind everything, here to bring your ideas into reality"
        />
        <div className={styles.teamWrap}>
          <TeamItem
            img="/images/rares.png"
            name="Rares"
            role="Developer/Designer"
            description="I'm a skilled Mobile/Full Stack Developer with expertise in React/React Native. Dedicated to delivering seamless user experiences and innovative solutions."
            linkedin="https://www.linkedin.com/in/itsrares/"
            twitter="https://twitter.com/thisRares"
            link="https://ahhzo.me"
          />
          <TeamItem
            img="/images/max.png"
            name="Max"
            role="Treat eater"
            description="Meet our adorable treat-loving pup! He brings endless joy with their wagging tail and irresistible charm. Ready to melt hearts with every treat-filled moment."
          />
        </div>
      </div>
    </Animation>
  );
};

export default TeamPage;
