import styles from "../styles/components/mainHeader.module.css";

const MainHeader = ({ title, subtitle }) => {
  return (
    <div className={styles.mainHeader}>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
};

export default MainHeader;
