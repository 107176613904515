import React from "react";
import styles from "../styles/components/contactBanner.module.css";
import Button from "./button";

const ContactBanner = () => {
  return (
    <div className={styles.infoBanner}>
      <div className={styles.infoBannerWrap}>
        <div className={styles.infoBannerText}>
          <p>Ready to start transforming your ideas into reality?</p>
        </div>
        <div className={styles.infoBannerButton}>
          <Button
            text="Lets Chat"
            link="../../../letschat"
            extraStyles={{ fontSize: 24, paddingLeft: 25, paddingRight: 25 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;
