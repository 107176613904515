import React from "react";
import styles from "../styles/components/checkboxField.module.css";

const Checkbox = ({ label, checked, onChange }) => {
  const handleCheckboxChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div className={styles.checkbox}>
      <label className={styles.checkboxLabel}>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
          className={styles.checkboxInput}
        />
        <span
          className={`${styles.checkboxCheckmark} ${
            checked ? styles.checked : ""
          }`}
        />
        <span className={styles.checkboxText}>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
