import styles from "../styles/pages/404.module.css";
import Button from "../components/button";
import Animation from "./animation";

const NotFound = () => {
  return (
    <Animation>
      <div className={styles.aboutHeader}>
        <div className={styles.aboutHeaderContent}>
          <div>
            <img
              src="/images/robot.png"
              width={150}
              height={150}
              alt="notfound"
            />
            <h1>ooops... 404</h1>
            <p>page not found!</p>
          </div>
          <Button text="Back to home" link="/" />
        </div>
      </div>
    </Animation>
  );
};

export default NotFound;
