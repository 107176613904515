import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Amplify } from "aws-amplify";
import awsconfig from "../src/aws-exports";
import ReactGA from "react-ga4";

Amplify.configure(awsconfig);
ReactGA.initialize("G-21Z9TNRHM5");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
