import styles from "../styles/components/footer.module.css";
import { NavLink } from "react-router-dom";
import { IoMail } from "react-icons/io5";
import { HiOutlineHeart } from "react-icons/hi";
import { CgCoffee } from "react-icons/cg";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrap}>
        <div className={styles.footer_left}>
          <img
            src="/images/logo-white.svg"
            className={styles.footerLogoImg}
            alt="logo"
          />
          <div className={styles.copyrightText}>
            <p>Copyright @ 2023 ahhzo</p>
          </div>
          <div>
            <a href="#" target="_blank" rel="noreferrer">
              <img
                src="/icons/facebook-white.svg"
                className={styles.footerSocialIcon}
                alt="facebook"
              />
            </a>
            <a
              href="https://www.twitter.com/ahhzo"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/icons/twitter-white.svg"
                className={styles.footerSocialIcon}
                alt="twitter"
              />
            </a>
            <a
              href="https://www.instagram.com/ahhzo"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/icons/instagram-white.svg"
                className={styles.footerSocialIcon}
                alt="instagram"
              />
            </a>
          </div>
        </div>
        <div className={styles.footer_middle}>
          <div>
            <h3>More Stuff</h3>
            <br />
            <div className={styles.footerLinkWrap}>
              <NavLink to="/privacy" className={styles.footerLink}>
                Privacy Policy
              </NavLink>
              <NavLink to="/terms" className={styles.footerLink}>
                Terms of Use
              </NavLink>
            </div>
          </div>
        </div>
        <div className={styles.footer_right}>
          <div>
            <h3>Stalk Us</h3>
            <br />
            <p>No office, we live on the internet</p>
            <a href="mailto:info@ahhzo.com">
              <p>
                <IoMail className={styles.icons} size={20} />
                info@ahhzo.com
              </p>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footerTag}>
        <div>
          <p>Made with</p>
          <HiOutlineHeart size={40} style={{ color: "#E74C3C" }} />
          <p>and</p>
          <CgCoffee size={40} style={{ color: "#CA6F1E" }} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
