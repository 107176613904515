import { motion } from "framer-motion";
import { useLayoutEffect } from "react";

const Animation = ({ children }) => {
  return (
    <motion.div
      key={Math.random().toString(36).substring(2)}
      initial="initial"
      animate="animate"
      transition={{ type: "linear", duration: 0.5 }} // Set the transition to linear
      variants={{
        initial: {
          opacity: 0,
          y: 10,
        },
        animate: {
          opacity: 1,
          y: 0,
        },
      }}
    >
      {children}
    </motion.div>
  );
};

export default Animation;
