import React, { useState } from "react";
import styles from "../styles/components/dropdownField.module.css";
import { IoChevronDownOutline } from "react-icons/io5";

const Dropdown = ({ title, options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.dropdown}>
      <label>{title}</label>
      <div className={styles.dropdownToggle} onClick={toggleDropdown}>
        {selectedOption ? selectedOption.label : "Select an option"}
        <IoChevronDownOutline
          size={19}
          style={{ marginBottom: -3, marginLeft: 3, marginRight: -3 }}
        />
      </div>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className={styles.dropdownItem}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
