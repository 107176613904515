import MainHeader from "../../components/mainHeader";
import ReactMarkdown from "react-markdown";
import Animation from "../animation";
import { useLayoutEffect } from "react";

const TermsPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Animation>
      <div>
        <MainHeader
          title="Terms of Use"
          subtitle="Effective Date: 17/06/2023"
        />
        <div style={{ maxWidth: 700, margin: "0 auto" }}>
          <ReactMarkdown
            children={`
These Terms of Use govern your use of the Ahhzo website and services. By accessing or using our website and services, you agree to abide by these terms and conditions.

## 1. User Responsibilities
- You must comply with all applicable laws and regulations.
- You are responsible for maintaining the confidentiality of your account information.
- You must not engage in any unauthorized use, modification, or distribution of our website or services.

## 2. Intellectual Property Rights
- All content and materials on the Ahhzo website are protected by intellectual property laws.
- You may not use, reproduce, modify, or distribute any content from our website without prior written permission.

## 3. Limitation of Liability
- Ahhzo and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our website or services.

## 4. Indemnification
- You agree to indemnify and hold Ahhzo and its affiliates harmless from any claims, losses, damages, liabilities, or expenses arising from your use of our website or services.

## 5. Third-Party Websites
- Our website may contain links to third-party websites. We do not endorse or control the content of these websites, and your use of them is at your own risk.

## 6. Termination
- Ahhzo reserves the right to terminate or suspend your access to our website or services at any time without prior notice.

## 7. Governing Law and Jurisdiction
- These Terms of Use shall be governed by and construed in accordance with the laws of Canada. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in Canada.

## 8. Changes to These Terms of Use
- Ahhzo reserves the right to modify or update these Terms of Use at any time. We will provide notice of any material changes.

If you have any questions or concerns regarding these Terms of Use, please contact us at info@ahhzo.com.
`}
          />
        </div>
      </div>
    </Animation>
  );
};

export default TermsPage;
