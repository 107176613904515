import styles from "../../styles/components/serviceMiniItem.module.css";

const ServiceMiniItem = ({ name, description, img }) => {
  return (
    <div className={styles.serviceMiniItem}>
      <div className={styles.serviceMiniItemLeft}>
        <img src={img} alt="icon" />
      </div>
      <div className={styles.serviceMiniItemRight}>
        <h1>{name}</h1>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ServiceMiniItem;
