import Button from "../../components/button";
import InputField from "../../components/inputField";
import styles from "../../styles/pages/signIn.module.css";

const SignInPage = () => {
  return (
    <div>
      <div>
        <div className={styles.signInHeader}>
          <h1>Welcome to dash!</h1>
          <p>
            This is your project dashboard, everything you need is right here.
          </p>
        </div>

        <div className={styles.signInInputWrap}>
          <div>
            <InputField title="Email" />
            <InputField title="Password" type="password" />
          </div>
          <Button text="Sign In" />
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
