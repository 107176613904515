import { DataStore } from "aws-amplify";
import Button from "../../components/button";
import InputField from "../../components/inputField";
import styles from "../../styles/pages/letsChat.module.css";
import { useLayoutEffect, useRef, useState } from "react";
import { LetsChat } from "../../../src/models";
import { Formik } from "formik";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { ColorRing } from "react-loader-spinner";
import Animation from "../animation";

const ReviewSchema = yup.object({
  Name: yup.string().required().min(3),
  Company: yup.string(),
  Email: yup.string().email().required().min(3),
  Details: yup.string().required().min(3),
  Mobile: yup
    .string()
    .matches(/^[6-9]\d{9}$/, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    })
    .required(),
  SelectOptions: yup
    .array()
    .min(1, "Please select atleast one (1) service")
    .required("Please select atleast one (1) service")
    .nullable(),
});

const LetsChatPage = () => {
  const selectedServices = useRef([]);
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const clickService = (name, props) => {
    let temp = selectedServices.current;
    if (temp.includes(name)) {
      temp = temp.filter((e) => e !== name);
    } else {
      temp.push(name);
    }

    props.setFieldValue("SelectOptions", temp);
    selectedServices.current = temp;
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const createLetsChat = ({ name, company, email, details, mobile, options }) =>
    new Promise((resolve, reject) => {
      let expandedOptions = options.join(", ");
      DataStore.save(
        new LetsChat({
          name,
          company,
          email,
          details,
          mobile,
          options: expandedOptions,
        })
      )
        .then(() => {
          setSuccessful(true);
          resolve(true);
        })
        .catch((e) => {
          reject(new Error("Error occurred trying, try again later"));
        })
        .finally(() => setLoading(false));
    });

  return (
    <Animation>
      <div>
        {loading ? (
          <div className={styles.loadingWrap}>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        ) : (
          [
            successful ? (
              <div className={styles.loadingWrap}>
                <h1>Wooo hooo!</h1>
                <p>
                  Thanks for reaching out to us, I can't wait to see where this
                  goes! I will get back to you very soon!
                </p>
              </div>
            ) : (
              <div className={styles.letsChatWrap}>
                <div className={styles.titleWrap}>
                  <h1>Lets chat how I can help!</h1>
                </div>

                <Formik
                  initialValues={{
                    Name: "",
                    Company: "",
                    Email: "",
                    Details: "",
                    Mobile: "",
                    SelectOptions: selectedServices.current,
                  }}
                  validationSchema={ReviewSchema}
                  onSubmit={(values, actions) => {
                    setLoading(true);
                    createLetsChat({
                      name: values.Name,
                      company: values.Company,
                      email: values.Email,
                      details: values.Details,
                      mobile: values.Mobile,
                      options: values.SelectOptions,
                    }).catch(async (e) => {
                      actions.setFieldError(
                        "general",
                        "Error occurred trying, try again later"
                      );
                    });
                  }}
                >
                  {(props) => (
                    <>
                      <div className={styles.formWrap}>
                        <div className={styles.formLeftWrap}>
                          <div className={styles.aboutInputsWrap}>
                            <div>
                              <div className={styles.aboutInputsWrapMargin}>
                                <InputField
                                  title="What’s your name?"
                                  onChangeText={props.handleChange("Name")}
                                  value={props.values.Name}
                                  error={
                                    props.touched.Name && props.errors.Name
                                  }
                                />
                              </div>
                              <InputField
                                title="and Company name"
                                onChangeText={props.handleChange("Company")}
                                value={props.values.Company}
                                error={
                                  props.touched.Company && props.errors.Company
                                }
                              />
                            </div>
                            <div>
                              <div className={styles.aboutInputsWrapMargin}>
                                <InputField
                                  title="also Email Address"
                                  onChangeText={props.handleChange("Email")}
                                  value={props.values.Email}
                                  error={
                                    props.touched.Email && props.errors.Email
                                  }
                                />
                              </div>
                              <InputField
                                title="finally, Mobile Number"
                                type="tel"
                                onChangeText={props.handleChange("Mobile")}
                                value={props.values.Mobile}
                                error={
                                  props.touched.Mobile && props.errors.Mobile
                                }
                              />
                            </div>
                          </div>

                          <div className={styles.servicesWrap}>
                            <div className={styles.servicesInfoWrap}>
                              <h1>What services are you after?</h1>
                              <p>
                                Learn more about what services we offer here
                              </p>
                            </div>
                            <div className={styles.servicesOptionsWrap}>
                              {[
                                "Web Development",
                                "App Development",
                                "General Development",
                                "Brand Identity",
                                "Logos",
                                "Other",
                              ].map((name) => {
                                return (
                                  <div
                                    className={styles.serviceWrap}
                                    onClick={(e) => {
                                      if (
                                        e.currentTarget.style
                                          .backgroundColor ===
                                        "rgb(30, 123, 105)"
                                      ) {
                                        e.currentTarget.style.backgroundColor =
                                          "#86C7BA";
                                      } else {
                                        e.currentTarget.style.backgroundColor =
                                          "#1E7B69";
                                      }

                                      clickService(name, props);
                                    }}
                                  >
                                    <h1>{name}</h1>
                                  </div>
                                );
                              })}
                            </div>
                            <p className={styles.servicesInfoError}>
                              {props.errors.SelectOptions}
                            </p>
                          </div>
                        </div>

                        <div className={styles.formRightWrap}>
                          <div className={styles.detailsInputsWrap}>
                            <p>
                              Let us know how I can help, what your project is,
                              any problems you need help and more
                            </p>
                            <InputField
                              title="Give us some details!"
                              multiline="multiline"
                              onChangeText={props.handleChange("Details")}
                              value={props.values.Details}
                              error={
                                props.touched.Details && props.errors.Details
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.formSubmit}>
                        <p>I will get back to you as soon as possible</p>
                        <Button text="Send" onClickPress={props.handleSubmit} />
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            ),
          ]
        )}
      </div>
    </Animation>
  );
};

export default LetsChatPage;
