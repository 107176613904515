import styles from "../styles/components/inputField.module.css";

const InputField = ({
  title,
  placeholder,
  type,
  multiline,
  extraWrapStyles,
  onChangeText,
  value,
  error,
}) => {
  return (
    <div
      id="inputWrap"
      className={styles.inputWrap}
      style={{ ...extraWrapStyles }}
    >
      <label>{title}</label>
      {!multiline ? (
        <input
          type={type}
          placeholder={placeholder}
          multiline={true}
          onChange={onChangeText}
          value={value}
        />
      ) : (
        <textarea onChange={onChangeText} value={value}></textarea>
      )}
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};

export default InputField;
