import MainHeader from "../../components/mainHeader";
import ReactMarkdown from "react-markdown";
import Animation from "../animation";
import { useLayoutEffect } from "react";

const PrivacyPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Animation>
      <div>
        <MainHeader
          title="Privacy Policy"
          subtitle="Effective Date: 17/06/2023"
        />
        <div style={{ maxWidth: 700, margin: "0 auto" }}>
          <ReactMarkdown
            children={`
At Ahhzo, we are committed to protecting the privacy and security of our users. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our freelancing development services. By accessing or using Ahhzo, you agree to the terms of this Privacy Policy.

## 1. Information We Collect
- Personal Information: Name, email address, contact information, and any other information you voluntarily provide to us.
- Usage Information: We may collect non-personal information about your interactions with our website, such as IP addresses, browser details, and cookies.

## 2. Use of Collected Information
- We use personal information to provide and improve our services, communicate with users, process payments, and deliver project requirements.
- Non-personal information is used for analytical purposes, to enhance user experience, and for website maintenance and security.

## 3. Information Sharing and Disclosure
- We do not sell, trade, or rent your personal information to third parties.
- We may share personal information with trusted third-party service providers who assist us in delivering our services.
- We may disclose personal information when required by law or to protect our rights, safety, or the rights, safety, or property of others.

## 4. Data Security
- We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
- However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.

## 5. Your Rights and Choices
- You have the right to access, correct, update, or delete your personal information.
- You may unsubscribe from marketing communications at any time by following the instructions provided in our emails.

## 6. Changes to This Privacy Policy
- We reserve the right to modify this Privacy Policy at any time. The updated policy will be effective upon posting.

If you have any questions or concerns regarding our Privacy Policy, please contact us at info@ahhzo.com.
`}
          />
        </div>
      </div>
    </Animation>
  );
};

export default PrivacyPage;
