import styles from "../styles/components/sectionHeader.module.css";
import Button from "./button";

const SectionHeader = ({ title, description, img, reversed }) => {
  return (
    <div
      className={
        !reversed
          ? styles.sectionHeader
          : `${styles.sectionHeader} ${styles.reversed}`
      }
    >
      <div className={styles.sectionHeaderInfo}>
        <h1>{title}</h1>
        <p>{description}</p>
        <Button text="Lets Chat" link="../../../letschat" />
      </div>
      <div className={styles.sectionHeaderImg}>
        <img src={img} alt={title} />
      </div>
    </div>
  );
};

export default SectionHeader;
