import styles from "../../styles/components/serviceItem.module.css";
import Button from "../button";

const ServiceItem = ({ name, description, url }) => {
  return (
    <div className={styles.serviceItem}>
      <h1>{name}</h1>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p style={{ minHeight: 110 }}>{description}</p>
        <Button
          text="Learn More"
          link={url}
          extraStyles={{
            backgroundColor: "#0B5345",
            borderWidth: 3,
            borderColor: "#000",
            borderStyle: "solid",
          }}
        />
      </div>
    </div>
  );
};

export default ServiceItem;
