import styles from "../../styles/components/offerItem.module.css";
import { Link } from "react-router-dom";
import { IoArrowForward } from "react-icons/io5";

const OfferItem = ({
  name,
  description,
  img,
  quoteButton,
  additions,
  offer,
  onOpen,
}) => {
  return (
    <div className={styles.offerItem}>
      <div className={styles.offerItemHeader}>
        {/* <img className={styles.offerItemImage} src={img} alt={name} /> */}
        <h1>{name}</h1>
      </div>
      <p>{description}</p>
      <div>
        {additions?.map((e) => {
          return (
            <div className={styles.offerAdditions}>
              <p>✅</p>
              <p>{e}</p>
            </div>
          );
        })}
      </div>
      {!offer ? (
        <Link to={quoteButton ? "" : "/letschat"} onClick={onOpen}>
          <div className={styles.offerQuoteWrap}>
            <h3 className={styles.offerQuote}>
              {quoteButton ? "Get Estimate" : "Let's chat"}
            </h3>
            <IoArrowForward size={19} />
          </div>
        </Link>
      ) : (
        <h3 className={styles.offerQuote}>{offer}</h3>
      )}
    </div>
  );
};

export default OfferItem;
