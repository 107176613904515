import React from "react";
import styles from "../styles/components/infoBanner.module.css";

const InfoBanner = () => {
  return (
    <div className={styles.infoBanner}>
      <div className={styles.infoBannerWrap}>
        <div className={styles.infoBannerItem}>
          <h1>Transparent</h1>
          <p>Upfront pricing</p>
        </div>
        <div className={styles.infoBannerItem}>
          <h1>Local</h1>
          <p>Supporting Ontario</p>
        </div>
        <div className={styles.infoBannerItem}>
          <h1>Quick & Easy</h1>
          <p>Services you want</p>
        </div>
      </div>
    </div>
  );
};

export default InfoBanner;
